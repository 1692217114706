
import ColorPalette from './ColorPalette.vue'
import ComponentPalette from './ComponentPalette.vue'
export default {
  data() {
    return {
      componentList:
        this.$store.state.settings.components !== null
          ? this.$store.state.settings.components.split(',')
          : [],
    }
  },
  methods: {
    UPDATE_COMPONENTS(components) {
      this.componentList = components
    },
  },
  components: {
    ColorPalette,
    ComponentPalette,
    Banner: () => import('./Banner.vue'),
    Banner2: () => import('./Banner-2.vue'),
    Banner3: () => import('./Banner-3.vue'),
    Category2: () => import('./Category-2.vue'),
    PopularCategories: () => import('./PopularCategories.vue'),
    PopularProducts: () => import('./PopularProducts.vue'),
    PopularProducts2: () => import('./PopularProducts2.vue'),
    About1: () => import('@/components/AboutUs/About1.vue'),
    Feedbacks: () => import('@/components/Feedbacks/index.vue'),
    EventListOfUsList: () => import('@/components/EventListOfUs/List.vue'),
    WebSiteCounts: () => import('./WebSiteCounts.vue'),
    Sponsor: () => import('./Sponsor.vue'),
    Showcase: () => import('./Showcase.vue'),
    Types: () => import('./Types.vue'),
    WebSiteVideo: () => import('./WebSiteVideo.vue'),
    MoreAboutMe: () => import('./MoreAboutMe.vue'),
    Blogs: () => import('./Blogs.vue'),
    Donetors: () => import('./Donetors.vue'),
    SeancesList: () => import('./SeancesList.vue'),
    Testimonials: () => import('../Testimonials/index.vue'),
  },
}
