
export default {
  name: 'IndexPage',
  middleware: ['returnUrl', 'onlyRedirectProject'],
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      title:
        this.$store.state.settings.contents[this.$i18n.locale].seo.home.title,
      link: [
        {
          hid: 'icon',
          rel: 'icon',
          type: 'image/x-icon',
          href: this.$store.state.settings.noImage,
        },
      ],
      meta: [
        { hid: 'og-type', property: 'og:type', content: 'website' },
        {
          hid: 'title',
          property: 'title',
          content:
            this.$store.state.settings.contents[this.$i18n.locale].seo.home
              .title,
        },
        {
          hid: 'author',
          property: 'author',
          content: 'Heocademy',
        },
        {
          hid: 'publisher',
          property: 'publisher',
          content: 'Heocademy',
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$store.state.settings.contents[
            this.$i18n.locale
          ].home.banner.overview.substring(0, 152),
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content:
            this.$store.state.settings.contents[this.$i18n.locale].seo.home
              .title,
        },
        {
          hid: 'og-desc',
          property: 'og:description',
          content: this.$store.state.settings.contents[
            this.$i18n.locale
          ].home.banner.overview.substring(0, 152),
        },
        {
          hid: 'og-image',
          property: 'og:image',
          content:
            this.$store.state.settings.contents[this.$i18n.locale].seo.home
              .image,
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content:
            this.$store.state.settings.contents[this.$i18n.locale].seo.home.url,
        },
        {
          hid: 't-type',
          name: 'twitter:card',
          content: this.$store.state.settings.website,
        },
        ...this.$store.state.settings.analyticSettings
          .filter((as) => as.key !== 'script')
          .map((asettings) => ({
            hid: asettings.key,
            name: asettings.key,
            content: asettings.value,
          })),
      ],
      script: [
        ...this.$store.state.settings.analyticSettings
          .filter((as) => as.key === 'script' && as.source === null)
          .map((asettings) => ({
            type: 'text/javascript',
            innerHTML: asettings.value,
          })),
        ...this.$store.state.settings.analyticSettings
          .filter((as) => as.key === 'script' && as.source !== null)
          .map((asettings) => ({
            type: 'text/javascript',
            src: asettings.source,
            innerHTML: asettings.value,
          })),
      ],
      __dangerouslyDisableSanitizers: ['script'],
    }
  },
  async fetch({ store, app }) {
    const currency = store.getters['user/getUserIP'].currency
    const filter = {
      page: 1,
      search: '',
      limit: 12,
      type: 5,
      categoryId: -1,
      currency: store.getters['user/getUserIP'].currency,
    }
    await Promise.all([
      store.dispatch('blogs/GET_BLOGS', filter),
      store.dispatch('popularProducts/GET_POPULAR_PRODUCTS', {
        currency,
        lang: app.i18n.locale,
      }),
    ])
  },
}
