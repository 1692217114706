
import AdminContentEditable from '~/mixins/AdminContentEditable'
import Modal from '@/components/Shared/Modal/index.vue'
export default {
  mixins: [AdminContentEditable],
  data() {
    return {
      show: false,
      colors: {
        primaryColor: '',
        themeColor: '',
        bannerColor: '',
      },
    }
  },
  components: {
    Modal,
  },
  methods: {
    UPDATE_COLOR(e, key) {
      const val = e.target.value
      const id = this.getSettings.settingsId
      this.UPDATE_CONTEXT(val, `Settings/${id}`, key, 1, id)
      this.colors[key] = val
    },
    GET_COLORS() {
      this.colors = {
        primaryColor: this.getSettings.primaryColor,
        themeColor: this.getSettings.themeColor,
        bannerColor: this.getSettings.bannerColor,
      }
    },
    UPDATE_COLORS() {
      location.reload()
    },
  },
  created() {
    this.GET_COLORS()
  },
}
